import React, { useState } from "react";
import { Icon } from 'react-icons-kit'
import { eye } from 'react-icons-kit/feather/eye'
import { eyeOff } from 'react-icons-kit/feather/eyeOff'
import * as authHttpServices from '../services/auth-service'
import * as userHttpServices from '../services/user-service'
import { useNavigate } from "react-router-dom";
const Form = () => {
    const [inputText, setInputText] = useState({
        username: "",
        email: "",
        password: "",
        comfirmPassword: "",
        first_name: "",
        last_name: "",
    });
    const [warnUsername, setWarnUsername] = useState(false);
    const [warnName, setWarnName] = useState(false);
    const [warnLastname, setWarnLastname] = useState(false);
    const [warnEmail, setWarnEmail] = useState(false);
    const [warnPassword, setWarnPassword] = useState(false);
    const [showPassword, setShowPassword] = useState(false);
    const [warnComfirmPassword, setWarnComfirmPassword] = useState(false);
    const [icon, setIcon] = useState(eyeOff);
    const [type, setType] = useState('password');
    //     const [icon, setIcon] = useState(eyeOff);
    const navigate = useNavigate();

    const handleInputChange = (event) => {
        const { name, value } = event.target;
        setInputText((prevState) => ({
            ...prevState,
            [name]: value
        }));
    };


    const handleToggle = () => {
        if (type === 'password') {
            setIcon(eye);
            setShowPassword((prevState) => !prevState);
            setType('text');
        }
        else {
            setIcon(eyeOff);
            setShowPassword((prevState) => !prevState);
            setType('password');
        }
    }

    const handleSubmit = (event) => {
        event.preventDefault();
        setWarnName(false)
        setWarnLastname(false)
        setWarnUsername(false);
        setWarnEmail(false);
        setWarnPassword(false);
        setWarnComfirmPassword(false);

        if (inputText.username === "") {
            setWarnUsername(true);
        }
        if (inputText.email === "") {
            setWarnEmail(true);
        }
        if (inputText.first_name === "") {
            setWarnName(true);
        }
        if (inputText.last_name === "") {
            setWarnLastname(true);
        }
        if (inputText.password === "") {
            setWarnPassword(true);
        }
        if (inputText.username === "") {
            setWarnComfirmPassword(true);
        }
        if (inputText.password !== inputText.comfirmPassword) {
            alert('password!!')
        }
        else {
            const fetchCreteUser = async () => {
                try {
                    let obj = {
                        "username": inputText.username,
                        "first_name": inputText.first_name,
                        "last_name": inputText.last_name,
                        "email": inputText.email,
                        "password": inputText.password,
                        "comfirm_password": inputText.comfirmPassword,
                    }
                    const response = await userHttpServices.addUser(obj);
                    let obj_group = {
                        org_id: Number(73),
                        group_id: Number(73),
                        user_id: Number(response.data.data.insertId)
                    }
                    await userHttpServices.addUserGroup(obj_group);
                    alert('ลงทะเบียนสำเร็จ')
                    navigate('/user/login')
                } catch (error) {
                    // Handle any errors here
                    console.log(error)
                    if (error.response) {
                        if (error.response.status === 400) {
                            alert('ป้อนชื่อผู้ใช้งานและรหัสผ่านของบัญชี ALLRICE ที่ได้ลงทะเบียนไว้')
                        } else if (error.response.status === 401) {
                            alert('ชื่อผู้ใช้หรือรหัสผ่านไม่ถูกต้อง')
                        }
                    } else {
                        console.error(error);
                    }
                }
            };

            // Call the fetchCreteUser function
            fetchCreteUser();
        }
    };

    return (
        <>
            <div id="background-image">
                <div className="login">
                    <div className="card-resgiter">
                        <div className="text">
                            <h3>ลงทะเบียน</h3>
                            <p>ป้อนข้อมูลประจำตัวเพื่อลงทะเบียนบัญชีของคุณ</p>
                        </div>
                        <form onSubmit={handleSubmit}>
                            <div className="input-text">
                                <input
                                    type="text"
                                    className={warnUsername ? "warning" : ""}
                                    placeholder="ป้อนชื่อผู้ใช้งาน"
                                    value={inputText.username}
                                    onChange={handleInputChange}
                                    name="username"
                                    autoComplete='off'
                                />
                            </div>
                            <div className="input-text">
                                <input
                                    type="text"
                                    className={warnName ? "warning" : ""}
                                    placeholder="ป้อนชื่อ"
                                    value={inputText.first_name}
                                    onChange={handleInputChange}
                                    name="first_name"
                                    autoComplete='off'
                                />
                            </div>
                            <div className="input-text">
                                <input
                                    type="text"
                                    className={warnLastname ? "warning" : ""}
                                    placeholder="ป้อนนามสกุล"
                                    value={inputText.last_name}
                                    onChange={handleInputChange}
                                    name="last_name"
                                    autoComplete='off'
                                />
                            </div>
                            <div className="input-text">
                                <input
                                    type="text"
                                    className={warnEmail ? "warning" : ""}
                                    placeholder="ป้อนอีเมล"
                                    value={inputText.email}
                                    onChange={handleInputChange}
                                    name="email"
                                    autoComplete='off'
                                />
                            </div>
                            <div className="input-text">
                                <input
                                    type={showPassword ? "text" : "password"}
                                    // className={`${warnPassword ? "warning" : ""} ${showPassword ? "type_password" : ""}`}
                                    className={`${warnPassword ? "warning" : ""}`}
                                    placeholder="ป้อนรหัสผ่าน"
                                    value={inputText.password}
                                    onChange={handleInputChange}
                                    name="password"
                                    autoComplete='off'
                                />
                                <span onClick={handleToggle} className='fa-eye'><Icon icon={icon} size={18} /></span>
                            </div>

                            <div className="input-text">
                                <input
                                    type={showPassword ? "text" : "password"}
                                    // className={`${warnComfirmPassword ? "warning" : ""} ${showPassword ? "type_password" : ""}`}
                                    className={`${warnComfirmPassword ? "warning" : ""}`}
                                    placeholder="ป้อนรหัสผ่านยืนยัน"
                                    value={inputText.comfirmPassword}
                                    onChange={handleInputChange}
                                    name="comfirmPassword"
                                    autoComplete='off'
                                />
                            </div>
                            <div className="buttons">
                                <button type="submit">ลงทะเบียน</button>
                            </div>
                            <div className="forgot">
                                <p>มีบัญชีผู้ใช้แล้ว  <a href="/user/login">เข้าสู่ระบบ</a></p>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </>
    );
};

export default Form;
