import React, { useState, useEffect } from 'react'
import Header from '../../components/header';
import RiceCalenderForm from '../../components/form/rice-calendar-form';
import BREED_MASTER_DATA from './breed-list.json';

export default function RiceCalenderMainPage() {
    const [data, setData] = useState([])
    const [breed_dropdown, setBreedDropdown] = useState([])

    const breedDropdownPerp = (light_type) =>{
        setBreedDropdown(BREED_MASTER_DATA['type'][light_type]['data'])
    }

    const handleDataChange = (e) =>{
        if(e.target.id === 'sensitive-1'){
            breedDropdownPerp('sensitive_light')
            setData({
                ...data,
                ['sensitive']: true
            })
        }else if(e.target.id === 'sensitive-2'){
            breedDropdownPerp('insensitive_light')
            setData({
                ...data,
                ['sensitive']: false
            })
        }else if(e.target.id === 'method-1' || e.target.id === 'method-2' || e.target.id === 'method-3'){
            setData({
                ...data,
                ['method']: e.target.value
            })
        }else{
            setData({
                ...data,
                [e.target.id]: e.target.value
            })
        }
    }

    const handleBackClick = () =>{
        window.location.href = "/home"
    }

    const handleSubmit = () =>{
        let light_type = data.sensitive ? 'sensitive_light' : 'insensitive_light'
        if(data.sensitive !== undefined && data.breed !== undefined && data.method !== undefined && data.date !== undefined){
            window.location.href = "/rice/calendar/detail?type="+light_type+"&breed="+data.breed+"&date="+data.date;
        }else{
            alert("คุณกรอกข้อมูลไม่ครบทุกช่อง \n กรุณากรอกข้อมูลให้ครบ")
        }
    }
    return(
        <>
            <div className="regisForm">
                <Header
                    title={"ปฏิทินการปฏิบัติเผื่อการผลิตข้าว"}
                    sub_title={"เริ่มวางแผนการผลิตข้าว"}
                    handleBackClick={handleBackClick}
                />
                <RiceCalenderForm
                    data={data}
                    handleDataChange={handleDataChange}
                    breed_dropdown={breed_dropdown}
                    handleSubmit={handleSubmit}
                />
            </div>
        </>
    )
}