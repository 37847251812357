/* eslint-disable no-unused-vars */
import React, { useEffect, useState, useRef } from 'react';
import { MapContainer, TileLayer, Polyline, Marker, Polygon, Popup, Tooltip, withLeaflet, GeoJSON } from 'react-leaflet';
import L from 'leaflet';
import { GeoJSONProps } from 'leaflet';
import 'leaflet/dist/leaflet.css'; // Import the Leaflet CSS
// import 'leaflet-geometryutil';
import * as turf from '@turf/turf';

import markerIcon from "../assets/images/location.png"; // Replace with your custom marker icon image URL
import { MdAddCircle, MdCheckCircle, MdReplayCircleFilled } from "react-icons/md";
import { BsFillCircleFill, BsDashLg } from "react-icons/bs";
import { IoMapOutline, IoCaretBackOutline } from "react-icons/io5";
import { MdGpsFixed, MdOutlineCameraAlt, MdOutlineCalculate } from "react-icons/md";
import { Col, Row, Carousel, Button, ProgressBar, Modal, Form, Nav, Navbar, Container, NavItem, NavLink } from 'react-bootstrap';
import { CiGps } from 'react-icons/ci';
import { LuUndo, LuPlus, LuCheck, LuLocateFixed, LuCalculator, LuCamera } from "react-icons/lu";
import { TbMathXy, TbShape, TbLine } from "react-icons/tb"
import { MdLocationPin } from 'react-icons/md'
import { FiChevronLeft } from "react-icons/fi";
import axios from 'axios';

import { useNavigate } from "react-router-dom";
import * as mapHttpServices from '../services/map-service'

const polygonRef = []

const MapComponent = () => {
    const navigate = useNavigate();
    const setColor = ({ properties }) => {
        return { weight: 1 };
    };
    const mapRef = useRef(null);
    const [currentLocation, setCurrentLocation] = useState(null);

    const [shape, setShape] = useState(false)
    const [selectedFeature, setSelectedFeature] = useState(null);

    const [pinCoordinates, setPinCoordinates] = useState(null);
    const [markerCoordinates, setMarkerCoordinates] = useState([]);
    const [polygonCoordinates, setPolygonCoordinates] = useState([]);
    const [area, setArea] = useState(0)


    const [name, setName] = useState("")
    const [note, setNote] = useState("")

    const [geojsonData, setGeojsonData] = useState(null);

    const onEachFeature = (feature, layer) => {
        layer.on({
            click: () => {
                setSelectedFeature(feature);
            }
        });
    };

    useEffect(() => {
        const fetchData = async () => {
            try {
                const response = await axios.get('https://maps-server.ricethailand.go.th/geoserver/Rice/ows?service=WFS&version=1.0.0&request=GetFeature&typeName=Rice:rice_sql&viewparams=id:06;farmer_id:1;project_id:238&outputFormat=application/json&format_options=callback:getJson');
                console.log('response', response.data)
                setGeojsonData(response.data);
            } catch (error) {
                console.error('Error fetching data from Geoserver:', error);
                alert('Error fetching data from Geoserver')
            }
        };

        fetchData();
    }, []);

    useEffect(() => {
        // Check if the browser supports Geolocation
        if ("geolocation" in navigator) {
            navigator.geolocation.getCurrentPosition(
                position => {
                    const { latitude, longitude } = position.coords;
                    const map = mapRef.current;

                    if (map) {
                        map.setView([latitude, longitude], 18);
                        setCurrentLocation([latitude, longitude]);
                    }
                },
                error => {
                    alert("Error getting current location:", error);
                }
            );
        } else {
            alert("Geolocation is not supported by your browser")
        }
    }, []);

    const calculatePolygonArea = (polygonRef) => {
        if (polygonRef.length >= 3) {
            const coordinates = polygonRef.map(({ lng, lat }) => [lng, lat]);
            coordinates.push([polygonCoordinates[0].lng, polygonCoordinates[0].lat]);
            const polygon = turf.polygon([coordinates]);
            const area = turf.area(polygon);

            setArea(area)
        } else {
            setArea(0)
        }
    }

    const handlePinLocation = () => {
        const map = mapRef.current;
        if (map && !pinCoordinates) {
            const center = map.getCenter();
            setPinCoordinates(center);
        } else {
            setPinCoordinates(null)
        }
    };

    const navigateToCurrentLocation = () => {
        const map = mapRef.current;
        if (map) map.setView(currentLocation, 18);
    }

    const handleClickAdd = () => {
        const map = mapRef.current;

        if (map) {
            const center = map.getCenter();
            console.log('Marker added at', center)
            console.log(polygonCoordinates.length)

            // Update the markers coordinates
            setMarkerCoordinates((prevMarkers) => [...prevMarkers, center]);

            // Update the polygon coordinates
            setPolygonCoordinates((prevCoordinates) => [...prevCoordinates, center]);

            polygonRef.push(center)
            calculatePolygonArea(polygonRef)
        }
    };

    const handleUndo = () => {
        // Remove the last added marker from the marker history

        polygonRef.pop()
        calculatePolygonArea(polygonRef)

        setMarkerCoordinates(prevMarkerHistory => {
            const updateCooredinates = [...prevMarkerHistory];
            updateCooredinates.pop();
            return updateCooredinates;
        });

        // Remove the last added polygon from the marker history
        setPolygonCoordinates(prevCoordinates => {
            const updateCooredinates = [...prevCoordinates];
            updateCooredinates.pop();
            return updateCooredinates;
        });
    };

    const handleSubmit = () => {
        const fetchSave = async () => {
            try {
                const coordinates = polygonCoordinates.map(obj => [obj.lng, obj.lat]);
                const outputObject = coordinates

                const obj = {
                    "map_data": {
                        "type": "Feature",
                        "id": "06",
                        "properties": { "name": "test6", "density": 0, "farmer_id": 1, "project_id": Math.floor(Math.random() * (1000 - 1 + 1)) + 1 },
                        "geometry": {
                            "type": "Polygon",
                            "coordinates": [outputObject]
                        }
                    }
                }

                // {
                //     "type": "Feature",
                //     "geometry": {
                //       "type": "Point",
                //       "coordinates": [longitude, latitude]
                //     },
                //     "properties": {
                //       "name": "Example Point"
                //     }
                //   }

                const res = await mapHttpServices.saveMap(obj);
                console.log(res)
                if (res.data.response.status) {
                    alert('Success')
                }
            } catch (error) {
                // Handle any errors here
                console.error(error);
            }
        };

        // Call the fetchSave function
        fetchSave();
    }


    const pointStyle = {
        position: 'absolute',
        top: '36%',
        left: '49.63%',
        transform: 'translate(-50%, -50%)',
        // width: '10px',
        // height: '10px',
        // borderRadius: '50%',
        // backgroundColor: 'red',
        zIndex: '9999',
    };

    // Create a Leaflet icon using the MdLocationPin icon from react-icons/md
    const customIcon = L.icon({
        iconUrl: MdLocationPin,
        iconSize: [30, 30],
    });

    return (
        <>
            <div className="regisForm">
                <div className="shadow-lg maps-header">
                    <div style={{ display: 'flex', alignItems: 'center', margin: '20px 0' }}>
                        <div style={{ margin: '0 20px' }}>
                            <FiChevronLeft style={{ backgroundColor: "white", borderRadius: '50px', padding: '2px' }} fontSize={38} color="#036445" onClick={() => navigate('/home')} />
                        </div>
                        <div>
                            <h2 style={{ fontWeight: 400, fontSize: 24, color: 'white', margin: '0' }}>แก้ไขข้อมูลแปลง</h2>
                            <p style={{ fontWeight: 400, fontSize: 14, color: 'white', margin: '0' }}>กรุณากรอกข้อมูลให้ครบถ้วน</p>
                        </div>
                    </div>
                </div>

                <div style={{ margin: '-40px 20px 0 20px', border: '1px solid black' }}>
                    <div style={pointStyle}><CiGps fontSize={42} /></div>
                    <MapContainer
                        center={[13.7367, 100.5231]}
                        zoom={13}
                        style={{ width: '100%', height: '350px' }}
                        ref={mapRef}
                    >
                        {/* <TileLayer url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png" /> */}
                        <TileLayer url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png" attribution="Map data © OpenStreetMap contributors" />

                        {/* Add GeoJSON component to display your data */}
                        {/* <GeoJSON data={seg4} style={setColor} onEachFeature={onEachFeature} /> */}
                        {geojsonData && <GeoJSON data={geojsonData} style={setColor} onEachFeature={onEachFeature} />}

                        {selectedFeature && (
                            <Modal show={true} onHide={() => setSelectedFeature(null)}>
                                <Modal.Header closeButton>
                                    <Modal.Title>Feature Details</Modal.Title>
                                </Modal.Header>
                                <Modal.Body>
                                    <pre>{JSON.stringify(selectedFeature, null, 2)}</pre>
                                </Modal.Body>
                            </Modal>
                        )}

                        {/* Render the markers */}
                        {markerCoordinates.map((marker, index) => (
                            <Marker key={index} position={marker} icon={L.divIcon(
                                {
                                    className: 'point-marker',
                                    html: '<div style="position: absolute; left: 0px; top: 0px; width: 9px; height: 9px; border-width: 1px; border-style: solid; border-radius: 6px; background-color: white; border-color: rgb(30, 144, 255);"></div>'
                                })
                            } />
                        ))}

                        {/* Render the polyline */}
                        {/* {polylineCoordinates.length > 0 && (
                            <Polyline positions={polylineCoordinates} color="blue" />
                        )} */}

                        {/* Render the polygon */}
                        {polygonCoordinates.length > 0 && (
                            <Polygon positions={polygonCoordinates} color="blue" />
                        )}

                        {currentLocation && (
                            <Marker position={currentLocation} icon={L.icon({
                                iconUrl: markerIcon,
                                iconSize: [25, 35],
                                iconAnchor: [12, 41],
                            })}>
                                <Popup>
                                    Current Location
                                </Popup>
                            </Marker>
                        )}

                        {pinCoordinates && (
                            <Marker position={pinCoordinates} icon={L.icon({
                                iconUrl: markerIcon,
                                iconSize: [25, 35],
                                iconAnchor: [12, 41],
                            })}>
                                {/* <Popup>
                                    Current Location
                                </Popup> */}
                                <Tooltip permanent direction="top" offset={[0, -45]}>
                                    <span style={{ fontWeight: 'bold' }}>x: {pinCoordinates.lat.toFixed(6)} ,y: {pinCoordinates.lng.toFixed(6)}</span>
                                </Tooltip>
                            </Marker>
                        )}

                    </MapContainer>
                </div>

                <div className="front-menu1" style={{ marginTop: '-55px' }}>
                    <LuUndo fontSize={40} style={{ color: '#ffffff', backgroundColor: markerCoordinates.length > 0 ? '#016545' : '#a9a9a9', borderRadius: '50%', padding: '5', marginTop: '10px', marginRight: '10px', boxShadow: '2px 2px 4px rgba(0, 0, 0, 0.2)' }} onClick={() => handleUndo()} />
                    <LuPlus fontSize={60} style={{ color: '#ffffff', backgroundColor: '#016545', borderRadius: '50%', padding: '5', marginRight: '10px', boxShadow: '2px 2px 4px rgba(0, 0, 0, 0.2)' }} onClick={() => handleClickAdd()} />
                    <LuCheck fontSize={40} style={{ color: '#ffffff', backgroundColor: markerCoordinates.length > 2 ? '#016545' : '#a9a9a9', borderRadius: '50%', padding: '5', marginTop: '10px', boxShadow: '2px 2px 4px rgba(0, 0, 0, 0.2)' }} onClick={() => console.log(markerCoordinates)} />
                </div>

                <div className="front-menu2" style={{ marginTop: '-155px' }}>
                    <div style={{ backgroundColor: '#ffffff', borderRadius: '50px', marginTop: '10px', boxShadow: '2px 2px 4px rgba(0, 0, 0, 0.2)' }}>
                        <TbMathXy fontSize={40} style={{ padding: '9px' }} onClick={() => handlePinLocation()} color={pinCoordinates ? '#016545' : '#000'} />
                    </div>
                    <div style={{ backgroundColor: 'rgba(255, 255, 255, 0.5)', borderRadius: '50px', marginTop: '10px', boxShadow: '2px 2px 4px rgba(0, 0, 0, 0.2)' }}>
                        {
                            shape === false ?
                                <TbLine fontSize={40} style={{ padding: '9px' }} onClick={() => { setShape(true); alert('This feature is currently unavailable.') }} />
                                :
                                <TbShape fontSize={40} style={{ padding: '9px' }} onClick={() => { setShape(false); alert('This feature is currently unavailable.') }} />
                        }
                    </div>
                </div>

                <div className="front-menu3" style={{ marginTop: '-155px' }}>
                    <div style={{ backgroundColor: '#ffffff', borderRadius: '50px', boxShadow: '2px 2px 4px rgba(0, 0, 0, 0.2)' }} >
                        <LuLocateFixed fontSize={40} style={{ padding: '9px' }} onClick={() => navigateToCurrentLocation()} />
                    </div>
                    <div style={{ backgroundColor: 'rgba(255, 255, 255, 0.5)', borderRadius: '50px', marginTop: '120px', boxShadow: '2px 2px 4px rgba(0, 0, 0, 0.2)' }} >
                        <LuCalculator fontSize={40} style={{ padding: '9px' }} onClick={() => alert('This feature is currently unavailable.')} />
                    </div>
                    <div style={{ backgroundColor: 'rgba(255, 255, 255, 0.5)', borderRadius: '50px', marginTop: '10px', boxShadow: '2px 2px 4px rgba(0, 0, 0, 0.2)' }} >
                        <LuCamera fontSize={40} style={{ padding: '9px' }} onClick={() => alert('This feature is currently unavailable.')} />
                    </div>
                </div>

                <div style={{ padding: '20px' }}>
                    <div className="shadow-sm mb-3" style={{ backgroundColor: 'white', borderRadius: '10px' }}>
                        <div style={{ color: 'black', padding: '10px' }}>
                            <span>ขนาดพื้นที่: </span>
                            <span style={{ color: '#016545' }}>{Math.round(parseFloat(area / 1600))} ไร่ </span>
                            <span style={{ color: '#016545' }}>{Math.round(parseFloat(area % 1600) / 400)} งาน </span>
                            <span style={{ color: '#016545' }}>{Math.round(parseFloat(area % 400) / 4)} ตารางวา</span>
                        </div>
                    </div>
                    <Form>
                        <Form.Group className="mb-3" controlId="formBasicEmail">
                            <Form.Label style={{ color: 'black' }}>ชื่อแปลง</Form.Label>
                            <Form.Control className="shadow-sm" type="name" placeholder="กรอกชื่อแปลง" onChange={(e) => setName(e.target.value)} />
                        </Form.Group>
                    </Form>
                    <Form>
                        <Form.Group className="mb-3" controlId="formBasicEmail">
                            <Form.Label style={{ color: 'black' }}>หมายเหตุ</Form.Label>
                            <Form.Control className="shadow-sm" type="note" placeholder="กรอกหมายเหตุ" onChange={(e) => setNote(e.target.value)} />
                        </Form.Group>

                        <Row style={{ padding: '40px 12px 0px 12px' }}>
                            <Button style={{ backgroundColor: '#016545', borderColor: '#016545' }} onClick={() => handleSubmit()}>บันทึก</Button>
                        </Row>
                    </Form>
                </div>
            </div >
        </>
    );
};

export default MapComponent;
