import React, { useState } from "react";
import { Icon } from 'react-icons-kit'
import { eye } from 'react-icons-kit/feather/eye'
import { eyeOff } from 'react-icons-kit/feather/eyeOff'

import * as authHttpServices from '../services/auth-service'

import { useNavigate } from "react-router-dom";
import Cookies from "js-cookie";

const Form = () => {
    const navigate = useNavigate();

    const [inputText, setInputText] = useState({
        username: "",
        password: ""
    });

    const [warnUsername, setWarnUsername] = useState(false);
    const [warnPassword, setWarnPassword] = useState(false);
    const [showPassword, setShowPassword] = useState(false);
    const [icon, setIcon] = useState(eyeOff);
    const [type, setType] = useState('password');
    //     const [icon, setIcon] = useState(eyeOff);

    const handleInputChange = (event) => {
        const { name, value } = event.target;
        setInputText((prevState) => ({
            ...prevState,
            [name]: value
        }));
    };

    const handleToggle = () => {
        if (type === 'password') {
            setIcon(eye);
            setShowPassword((prevState) => !prevState);
            setType('text');
        }
        else {
            setIcon(eyeOff);
            setShowPassword((prevState) => !prevState);
            setType('password');
        }
    }
    
    const handleBack = () => {
        console.log("eiei");
        window.location.replace("https://allrice.ricethailand.go.th/")
    }
    const handleSubmit = (event) => {
        event.preventDefault();

        setWarnUsername(false);
        setWarnPassword(false);

        if (inputText.username === "") {
            setWarnUsername(true);
        }
        if (inputText.password === "") {
            setWarnPassword(true);
            alert('ชื่อผู้ใช้หรือรหัสผ่านไม่ถูกต้อง')
        } else {
            const fetchAuth = async () => {
                try {
                    let obj = {
                        "username": inputText.username,
                        "password": inputText.password,
                        "app_id": 1,
                    }

                    const res = await authHttpServices.adminLogin(obj);
                    if (res.data.response.status) {
                        const accessToken = res.data.data[0].accessToken
                        console.log('accessToken',accessToken)
                        alert('เข้าสู่ระบบสำเร็จ')
                        localStorage.setItem('accessToken',accessToken)
                        navigate('/home')
                        
                    }
                } catch (error) {
                    // Handle any errors here
                    if (error.response) {
                        if (error.response.status === 400) {
                            alert('ป้อนชื่อผู้ใช้งานและรหัสผ่านของบัญชี ALLRICE ที่ได้ลงทะเบียนไว้')
                        } else if (error.response.status === 401) {
                            alert('ชื่อผู้ใช้หรือรหัสผ่านไม่ถูกต้อง')
                        }
                    } else {
                        console.error(error);
                    }
                }
            };

            // Call the fetchAuth function
            fetchAuth();
        }
    };

    return (
        <>
            <div id="background-image">
                <div className="login">
                    <div className="card">
                        <div className="text">
                            <h3>ยินดีต้อนรับ</h3>
                            <p>ป้อนข้อมูลประจำตัวเพื่อเข้าถึงบัญชีของคุณ</p>
                        </div>
                        {/* <form onSubmit={handleSubmit}> */}
                            <div className="input-text">
                                <input
                                    type="text"
                                    className={warnUsername ? "warning" : ""}
                                    placeholder="ป้อนชื่อผู้ใช้งาน"
                                    value={inputText.username}
                                    onChange={handleInputChange}
                                    name="username"
                                    autoComplete='off'
                                />
                            </div>
                            <div className="input-text">
                                <input
                                    type={showPassword ? "text" : "password"}
                                    // className={`${warnPassword ? "warning" : ""} ${showPassword ? "type_password" : ""}`}
                                    className={`${warnPassword ? "warning" : ""}`}
                                    placeholder="ป้อนรหัสผ่าน"
                                    value={inputText.password}
                                    onChange={handleInputChange}
                                    name="password"
                                    autoComplete='off'
                                />
                                <span onClick={handleToggle} className='fa-eye'><Icon icon={icon} size={18} /></span>
                            </div>
                            <div className="buttons">
                                <button onClick={handleSubmit} type="submit">เข้าสู่ระบบ</button>
                            </div>
                            <div className="buttons-back">
                                <button onClick={handleBack}>ย้อนกลับ</button>
                            </div>
                            <div className="forgot">
                                <p>
                                    ยังไม่มีบัญชีผู้ใช้? <a href="/user/register">ลงทะเบียนทันที </a>ลืมรหัสผ่าน? <a href="/user/login" style={{ color: 'red' }}>รีเซ็ตรหัสผ่าน</a>
                                </p>
                            </div>
                            
                        {/* </form> */}
                    </div>
                </div>
            </div>
        </>
    );
};

export default Form;
