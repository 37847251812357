import React, { useEffect, useState } from "react";
import {
    Card,
    Form,
    Button
} from 'react-bootstrap';

export default function RiceCalenderForm({
    data,
    handleDataChange,
    breed_dropdown,
    handleSubmit
}) {
    return (
        <div
            style={{
                marginTop: '1rem',
                marginLeft: '1rem',
                marginRight: '1rem'
            }}
        >
            <Card style={{ width: '100%', height:'100%' }}>
                <Card.Body>
                    <Form>
                        <Form.Group className="mb-3" controlId="formGroupSensitive">
                            <Form.Label style={{ color: 'black' }}>เลือกประเภทพันธุ์ข้าวที่ปลูก</Form.Label>
                            <Form.Check // prettier-ignore
                                type={'radio'}
                                id={'sensitive-1'}
                                label={'ไวต่อช่วงแสง'}
                                onChange={handleDataChange}
                                checked={data.sensitive === true}
                                value={1}
                            />
                            <Form.Check // prettier-ignore
                                type={'radio'}
                                id={'sensitive-2'}
                                label={'ไม่ไวต่อช่วงแสง'}
                                onChange={handleDataChange}
                                checked={data.sensitive === false}
                                value={0}
                            />
                        </Form.Group>
                        <Form.Group className="mb-3" controlId="breed_group">
                            <Form.Label style={{ color: 'black' }}>พันธุ์ข้าวที่ปลูก</Form.Label>
                            <Form.Select 
                                aria-label="Default select example"
                                id="breed"
                                onChange={handleDataChange}
                            >
                                <option id={`breed_item_0`} value={0}>กรุณาเลือกพันธุ์</option>
                            {
                                breed_dropdown.map((item, i) => {
                                    // if(item.time_line !== undefined){
                                        return (
                                            <option id={`breed_item_${i}`} value={i}>{item.name_short}</option>
                                        )
                                    // }
                                })
                            }
                            </Form.Select>
                        </Form.Group>
                        <Form.Group className="mb-3" controlId="method">
                            <Form.Label style={{ color: 'black' }}>วิธีการปลูก</Form.Label>
                            <Form.Check // prettier-ignore
                                type={'radio'}
                                id={'method-1'}
                                label={' หว่าน/หยอด/โรยเมล็ด ข้าวแห้ง'}
                                onChange={handleDataChange}
                                checked={data.method === '1'}
                                value={1}
                            />
                            <Form.Check // prettier-ignore
                                type={'radio'}
                                id={'method-2'}
                                label={'หว่านน้ำตม'}
                                onChange={handleDataChange}
                                checked={data.method === '2'}
                                value={2}
                            />
                            <Form.Check // prettier-ignore
                                type={'radio'}
                                id={'method-3'}
                                label={'ปักดำ'}
                                onChange={handleDataChange}
                                checked={data.method === '3'}
                                value={3}
                            />
                        </Form.Group>
                        <Form.Group className="mb-3" controlId="date2">
                            <Form.Label style={{ color: 'black' }}>วันที่ปลูก</Form.Label>
                            <Form.Control
                                type="date"
                                id="date"
                                onChange={handleDataChange}
                                value={data.date}
                            />
                        </Form.Group>
                        <Button variant="primary" onClick={handleSubmit}>วางแผนการผลิต</Button>
                    </Form>
                </Card.Body>
            </Card>
        </div>
    )
}
