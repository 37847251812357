import React, { useState, useEffect } from 'react'
import { useLocation } from 'react-router-dom'
import Header from '../../components/header';
import RiceCalenderForm from '../../components/form/rice-calendar-form';
import BREED_MASTER_DATA from './breed-list.json';
import PREIOD_MASTER_DATA from './preoid.json';
import BUG_MASTER_DATA from './bug.json';
import CalendarCard from '../../components/caldendar-card';
import { Modal } from 'react-bootstrap';

export default function RiceCalenderDetailsPage() {
    const location = useLocation()
    const query_param = new URLSearchParams(location.search)
    const [light_type, setLightType] = useState(query_param.get('type'))
    const [breed, setBreed] = useState(query_param.get('breed'))
    const [date, setDate] = useState(query_param.get('date'))
    const [title, setTitle] = useState("")
    const [sub_title, setSubTitle] = useState("")
    const [rice_plan, setRicePlan] = useState([])
    const [modal_title, setModalTitle] = useState("")
    const [modal_body, setModalBody] = useState("")
    const [show, setShow] = useState(false);

    const handleClose = () => setShow(false);

    const thai_month = ['มค', 'กพ', 'มีค', 'เมย', 'พค', 'มิย', 'กค', 'สค', 'กย', 'ตค', 'พย', 'ธค']
    const textPeroid = [
        'ก่อนเพาะปลูก',
        'ระยะกล้า',
        'ระยะแตกกอ',
        'ระยะตั้งท้อง',
        'ระยะน้ำนมและข้าวสุกแก่',
    ]

    const handleClickPeroid = (id) => {
        setModalTitle("คำแนะนำ "+textPeroid[id])
        setModalBody(PREIOD_MASTER_DATA[light_type][id])
        setShow(true)
    }

    const handleClickCard = (type) => {
        if (type === "bug1" || type === 'bug2' || type === 'bug3') {
            setModalTitle("โรค และแมลงศัตรูพืช")
            setModalBody(BUG_MASTER_DATA[light_type][type])
            setShow(true)
        }
    }
    
    const handleBackClick = () =>{
        window.location.href = "/rice/calendar"
    }

    useEffect(() => {
        setTitle("วางแผนการผลิตข้าว " + BREED_MASTER_DATA['type'][light_type]['data'][parseInt(breed)]['name_short'])
        setSubTitle("ตั้งแต่วันที่ " + date)
        let start_date = new Date(date)
        console.log(start_date);
        let temp_list = []
        // if(BREED_MASTER_DATA['type'][light_type]['data'][parseInt(breed)]['name_short'])
        let master_data = BREED_MASTER_DATA['type'][light_type]['data'][parseInt(breed)]['time_line']
        if(light_type === 'sensitive_light' && (parseInt(breed) !== 11 && parseInt(breed) !== 65)){
            master_data = BREED_MASTER_DATA['type'][light_type]['data'][11]['time_line']
        }else if(light_type === 'insensitive_light' && (parseInt(breed) !== 1 && parseInt(breed) !== 3)){
            master_data = BREED_MASTER_DATA['type'][light_type]['data'][1]['time_line']
        }
        for (let plan_item of master_data) {
            let result = new Date(date);
            result.setDate(result.getDate() + plan_item.duration);
            let date_str = result.getDate() + " " + thai_month[result.getMonth()] + " " + (result.getFullYear() + 543)
            temp_list.push({
                "date": date_str,
                "type": plan_item.type,
                "title": plan_item.title,
                "sub_title": plan_item.sub_title,
                "period": parseInt(plan_item.period)
            })
        }
        setRicePlan(temp_list)
    }, []);
    return (
        <>
            <div className="regisForm">
                <Header
                    title={title}
                    sub_title={sub_title}
                    handleBackClick={handleBackClick}
                />
                <div
                    style={{
                        marginTop: '1rem',
                        marginLeft: '1rem',
                        marginRight: '1rem'
                    }}
                >
                    {
                        rice_plan.map((item, i) => {
                            return (
                                <CalendarCard
                                    date={item.date}
                                    type={item.type}
                                    title={item.title}
                                    subtitle={item.subtitle}
                                    period={item.period}
                                    index={i}
                                    onClickCard={(id) => {
                                        handleClickCard(id)
                                    }}
                                    onClickPeroid={(id) => {
                                        handleClickPeroid(id)
                                    }}
                                />
                            )
                        })
                    }
                </div>
                <Modal show={show} onHide={handleClose}>
                    <Modal.Header closeButton>
                        <Modal.Title>{modal_title}</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>{modal_body}</Modal.Body>
                </Modal>
            </div>
        </>
    )
}