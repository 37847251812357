import axios from "axios";
import config from '../config.json';
const token = localStorage.getItem('accessToken');
export const addUser = async (obj) => {
    return axios.post(config.BASE_API + '/users/create', obj, {
        headers: {
            'Content-Type': 'application/json',
        }
    })
}
export const addUserGroup = async (obj) => {
    return axios.post(config.BASE_API + '/organizations/users/add', obj, {
        headers: {
            'Content-Type': 'application/json',
            // 'Authorization': 'Bearer ' + token,
        }
    })
}

