import React, { useEffect, useState } from "react";
import { Col, Row, Badge } from 'react-bootstrap';

function CalendarCard({ date, type, title, subtitle, period, index, onClickPeroid, onClickCard }) {
    const textPeroid = [
        'ก่อนเพาะปลูก',
        'ระยะกล้า',
        'ระยะแตกกอ',
        'ระยะตั้งท้อง',
        'ระยะน้ำนมและข้าวสุกแก่',
    ]
    const textType = {
        "growth": "การปลูก",
        "fertilizer": "การใส่ปุ๋ย",
        "weed": "การกำจัดวัชพืช",
        "bug1": "การสำรวจโรคแมลง",
        "bug2": "การสำรวจโรคแมลง",
        "bug3": "การสำรวจโรคแมลง"
    }
    return (
        <>
            <Row>
                <Col xs="12" className="calendar-box">
                    <div className="apland-timeline-area">
                        <div className="single-timeline-area">
                            <div className="timeline-date wow fadeInLeft" onClick={() => { onClickPeroid(period) }}>
                                <p>
                                    {date}
                                    <br />
                                    ({textPeroid[period]})
                                </p>
                            </div>
                            <Row>
                                <Col xs="12" onClick={() => { onClickCard(type) }}>
                                    <div className="single-timeline-content d-flex wow fadeInLeft">
                                        <div className="timeline-text">
                                            <Badge className={"badge-type-" + type}>{textType[type]}</Badge><br /><br />
                                            <h6>{title}</h6>
                                            <p>{subtitle}</p>
                                        </div>
                                    </div>
                                </Col>
                            </Row>
                        </div>
                    </div>
                </Col>
            </Row>
        </>
    )
}
export default CalendarCard