import axios from "axios";
import config from '../config.json';

export const saveMap = async (obj) => {
    console.log(obj)
    return axios.post(config.SAVE_MAP_API + '/save_map', obj, {
        headers: {
            'Content-Type': 'application/json'
        }
    })
}
