import React, { useEffect, useState } from 'react';
import { MapContainer, TileLayer, Polygon } from 'react-leaflet';
// import axios from 'axios';

const PolygonCenterFinder = (props) => {
  const { farmGeoFence, area } = props
  const [center, setCenter] = useState(null);

  useEffect(() => {
    const fetchData = async () => {
      try {
        // console.log('farmGeoFence', farmGeoFence)
        // console.log('area', area)

        const nestedArray = farmGeoFence.map(coord => [coord.lat, coord.lng]);
        // console.log('nestedArray',nestedArray)

        // const response = await axios.get('https://maps-server.ricethailand.go.th/geoserver/Rice/ows?service=WFS&version=1.0.0&request=GetFeature&typeName=Rice:test_sql&viewparams=n:' + name + '&outputFormat=application/json&format_options=callback:getJson'); 
        // const response = await axios.get('https://maps-server.ricethailand.go.th/geoserver/Rice/ows?service=WFS&version=1.0.0&request=GetFeature&typeName=Rice:rice_sql&viewparams=id:04;farmer_id:14;project_id:00&outputFormat=application/json&format_options=callback:getJson');
        // let nestedArray = response.data.features[0].geometry.coordinates[0]
        // console.log('polygonCoordinates',polygonCoordinates)
        // setGeojsonData(response.data);
        // console.log('response: ', response.data)


        // // // Calculate the center of the polygon when the polygonCoordinates prop changes
        if (nestedArray.length > 0) {
          const latSum = nestedArray.reduce((sum, coordinate) => sum + coordinate[0], 0);
          const lngSum = nestedArray.reduce((sum, coordinate) => sum + coordinate[1], 0);
          const avgLat = latSum / nestedArray.length;
          const avgLng = lngSum / nestedArray.length;

          setCenter([avgLat, avgLng]);
          // console.log(avgLat, avgLng)

        }

      } catch (error) {
        console.error('Error fetching data from Geoserver:', error);
        // alert('Error fetching data from Geoserver')
      }
    };

    fetchData();
  }, [area, farmGeoFence]);

  const calculateZoom = () => {
    if (area < 5000) {
      return 17
    } else if (area < 10000) {
      return 16
    } else if (area < 100000) {
      return 15
    } else if (area < 1000000) {
      return 14
    } else if (area < 1500000) {
      return 13
    }
    else {
      return 12
    }
  }

  if (!center) return null

  return (
    <MapContainer
      center={center}
      zoomControl={false}
      dragging={false}
      style={{ width: '100px', height: '100px' }}
      attributionControl={false}
      zoom={calculateZoom()}
    >
      <TileLayer url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png" />
      <Polygon positions={farmGeoFence.map(coord => [coord.lat, coord.lng])} />
    </MapContainer >
  );

};

export default PolygonCenterFinder;
