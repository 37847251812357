import React, { useRef, useEffect, useState } from 'react';
import { MapContainer, TileLayer, Marker, Polygon, useMap } from 'react-leaflet';
import L from 'leaflet';
import 'leaflet/dist/leaflet.css';
import crosshair from './../assets/images/crosshair_black.png';
import { Backdrop, CircularProgress } from '@mui/material';
import LoadingOverlay from "react-loading-overlay";
LoadingOverlay.propTypes = undefined

export default function App() {
    const mapRef = useRef();
    const markerRef = useRef();
    const [markers, setMarkers] = useState([]);
    const [polygon, setPolygon] = useState(null);
    const [initialPosition, setInitialPosition] = useState([13.7367, 100.5231]);
    const [isBusy, setBusy] = useState(true)

    const [open, setOpen] = React.useState(false);
    const handleClose = () => {
        setOpen(false);
    };
    const handleOpen = () => {
        setOpen(true);
    };

    // useEffect(() => {
    //     navigator.geolocation.getCurrentPosition(
    //         (position) => {
    //             const { latitude, longitude } = position.coords;
    //             setInitialPosition([latitude, longitude]);
    //             console.log([latitude, longitude]);
    //             setBusy(false)
    //         },
    //         (error) => {
    //             console.error(error);
    //         }
    //     );
    // }, []);

    useEffect(() => {
        if ("geolocation" in navigator) {
            navigator.geolocation.getCurrentPosition((position) => {
                const { latitude, longitude } = position.coords;
                console.log(latitude, longitude)
                if (mapRef.current) {
                    setInitialPosition([latitude, longitude]);
                    mapRef.current.flyTo([latitude, longitude], 18);
                    setBusy(false)
                }
            });
        }
        else {
            console.log("Geolocation is not supported by your browser")
            alert("Geolocation is not supported by your browser")
        }
    }, []);

    const crosshairsIcon = new L.Icon({
        iconUrl: crosshair,
        iconSize: [32, 32],
        iconAnchor: [16, 16],
    });

    const MoveCrosshairs = () => {
        const map = useMap();

        useEffect(() => {
            const updateMarkerPosition = () => {
                const { lat, lng } = map.getCenter();
                markerRef.current.setLatLng([lat, lng]);
            };

            map.on('move', updateMarkerPosition);
            updateMarkerPosition(); // Initial position update

            return () => {
                map.off('move', updateMarkerPosition);
            };
        }, [map]);

        return null;
    };

    const updatePolygon = (updatedMarkers) => {
        const positions = updatedMarkers.map(marker => marker.getLatLng());
        setPolygon(<Polygon positions={positions} />);
    };

    const handleClick = () => {
        const { lat, lng } = markerRef.current.getLatLng();
        console.log('Clicked at:', lat, lng);

        const newMarkerIcon = new L.divIcon({
            className: 'custom-marker',
            html: `<div class="circle-icon"}>${markers.length + 1}</div>`,
            iconSize: [32, 32],
            iconAnchor: [16, 16],
        });

        const newMarker = L.marker([lat, lng], { icon: newMarkerIcon }).addTo(mapRef.current);

        setMarkers((prevMarkers) => [...prevMarkers, newMarker]);
        updatePolygon([...markers, newMarker]);
    };

    const handleUndo = () => {
        const lastMarker = markers.pop();
        if (lastMarker) {
            mapRef.current.removeLayer(lastMarker);
            setMarkers([...markers]);
            updatePolygon([...markers]);
        }
    };

    return (
        <div >
            <LoadingOverlay
                active={isBusy}
                spinner
                text={<p style={{ color: 'white', fontSize: '16px' }}>กำลังโหลด</p>}
            >
                <div>
                    <div className="map-container">
                        <MapContainer
                            center={initialPosition}
                            zoom={15}
                            style={{ width: '100%', height: '100vh' }}
                            ref={mapRef}
                            zoomControl={false}
                        >
                            <TileLayer
                                url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                                attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
                            />

                            <MoveCrosshairs />
                            <Marker position={initialPosition} icon={crosshairsIcon} ref={markerRef} zIndexOffset={1000} />

                            {polygon}
                        </MapContainer>
                    </div>
                    <button onClick={handleClick}>Add Marker</button>
                    <button onClick={handleUndo}>Undo Last Marker</button>
                </div>
            </LoadingOverlay>

        </div >
    );
}
