/* eslint-disable no-unused-vars */

import React, { useEffect, useState } from "react";
import { Col, Row, Carousel, Button, ProgressBar, Modal, Form, Nav, Navbar, Container, NavItem, NavLink } from 'react-bootstrap';
import { useNavigate } from "react-router-dom";
import { FiChevronLeft, FiHome, FiPlus } from "react-icons/fi";
import { MdAddCircle } from "react-icons/md";
import Map from '../components/polygonCenterFinder'
import { jwtDecode, InvalidTokenError } from "jwt-decode";
function Home() {
    const navigate = useNavigate();
    const [userData, setUserData] = useState({
        "id": 14,
        "org_id": 2,
        "user_id": 14,
        "group_id": 15,
        "username": "ITRDadmin",
        "first_name": "",
        "last_name": "",
        "menu_list": [],
        "permis_list": [],
        "iat": 1702367446,
        "exp": 1702371046
    })
    const [farm, setFarm] = useState([])
    const [farmer, setFarmer] = useState()
    const [isLoading, setLoading] = useState(true)

    const saveMapGeo = (farmer, farm) => {
        const coordinates = farm.attributes.farm_geo_fence
        const nestedArray = coordinates.map(coord => [coord.lat, coord.lng]);

        const obj = {
            map_data: {
                type: "Feature",
                id: farmer.id,
                properties: {
                    name: farm.attributes.farm_name,
                    density: farm.attributes.farm_area,
                    farmer_id: farm.id,
                    project_id: '00'
                },
                geometry: {
                    type: "Polygon",
                    coordinates: [nestedArray]
                }
            }
        };

        console.log('obj', obj)
    }

    useEffect(() => {
        const storedToken = localStorage.getItem('accessToken');

        const decoded = jwtDecode(storedToken);
        console.log('decoded', decoded)
        setUserData(decoded)
        // setFarm(decoded.personal.data[0].attributes.fram.data)
        // setFarmer(decoded.personal.data[0].attributes.farmer.data)
        setLoading(false)
    }, []);

    if (isLoading) return (<></>)

    return (
        <>
            <div className="regisForm">
                <div className="shadow-lg maps-header">
                    <div style={{ display: 'flex', alignItems: 'center', margin: '20px 0' }}>
                        {/* <div style={{ margin: '0 20px' }}>
                            <FiChevronLeft style={{ backgroundColor: "white", borderRadius: '50px', padding: '2px' }} fontSize={38} color="#036445" />
                        </div> */}
                        <div>
                            <h2 style={{ fontWeight: 400, fontSize: 24, color: 'white', margin: '0' }}>สวัสดี! ยินดีต้อนรับ</h2>
                            <p style={{ fontWeight: 400, fontSize: 14, color: 'white', margin: '0' }}>คุณ {userData.first_name} {userData.last_name}</p>
                        </div>
                    </div>
                </div>

                {/* <div style={{ margin: '-60px 20px 100px 20px' }}> */}
                <div>
                    <Row>
                        {farm.map((item, index) => (
                            <Col xs={12} sm={12} md={6} lg={4} key={index}>
                                {/* {console.log('item', item)} */}
                                <div className="shadow"
                                    style={{
                                        backgroundColor: 'white',
                                        borderRadius: '10px',
                                        marginTop: 15
                                    }}
                                    // onClick={() => navigate('/map/edit', {
                                    //     state: {
                                    //         farmGeoFence: item.attributes.farm_geo_fence,
                                    //         area: item.attributes.farm_area,
                                    //         name: item.attributes.farm_name,
                                    //         note: '-'
                                    //     }
                                    // })}
                                    onClick={() => saveMapGeo(farmer, item)}
                                >
                                    <div className="d-flex align-items-center p-3">
                                        <div>
                                            <Map farmGeoFence={item.attributes.farm_geo_fence} area={item.attributes.farm_area} />
                                        </div>
                                        <div className="w-100" style={{ marginLeft: 15, lineHeight: 0.75 }}>
                                            <h5 style={{ color: '#f69e0b' }}>{item.attributes.farm_name}</h5>
                                            <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                                                <p style={{ fontWeight: 400, fontSize: 14 }}>อายุข้าว</p>
                                                <p style={{ fontWeight: 400, fontSize: 14, color: '#c4c4c4' }}>ไม่มีข้อมูล</p>
                                            </div>
                                            <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                                                <p style={{ fontWeight: 400, fontSize: 14 }}>วันที่เริ่มปลูก</p>
                                                <p style={{ fontWeight: 400, fontSize: 14, color: '#c4c4c4' }}>ไม่มีข้อมูล</p>
                                            </div>
                                            <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                                                <p style={{ fontWeight: 400, fontSize: 14 }}>วันที่เก็บเกี่ยว</p>
                                                <p style={{ fontWeight: 400, fontSize: 14, color: '#c4c4c4' }}>ไม่มีข้อมูล</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </Col>
                        ))}
                    </Row>
                </div>
                <div className="new-home-box">
                    <Row>
                        <Col xs="12">
                            <h3>รายการดำเนินการ</h3>
                        </Col>
                    </Row>
                    <Row>
                        <Col xs="12">
                            <a className="btn btn-primary" href="/rice/calendar" role="button">เปิดปฏิทินเพาะปลูก</a>
                        </Col>
                    </Row>
                </div>
            </div>

            {/* <div className="shadow-lg" style={{ backgroundColor: 'white', position: 'fixed', bottom: 0, left: 0, right: 0, borderRadius: '30px 30px 0 0' }}>
                <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '70px' }}>
                    <div>
                        <FiHome fontSize={30} className="center" />
                        <p style={{ fontWeight: 400, fontSize: 12, margin: '0' }}>หน้าหลัก</p>
                    </div >
                </div>
            </div>

            <div className="sticky-button" >
                <FiPlus color="white" fontSize={80} style={{ backgroundColor: '#016545', borderRadius: '50px', padding: '10px' }} onClick={() => navigate("/map/create")} />
            </div> */}
        </>
    )
}

export default Home;
