import React, { useEffect, useState } from "react";
import { Col, Row, Carousel, Button, ProgressBar, Modal, Form, Nav, Navbar, Container, NavItem, NavLink } from 'react-bootstrap';
import { useNavigate } from "react-router-dom";
import { FiChevronLeft, FiHome, FiPlus } from "react-icons/fi";

export default function Header({
    title,
    sub_title,
    handleBackClick
}) {
    return (
        <div className="shadow-lg maps-header">
            <div style={{ display: 'flex', alignItems: 'center', margin: '20px 0' }}>
                <div style={{ margin: '0 20px' }}>
                    <FiChevronLeft style={{ backgroundColor: "white", borderRadius: '50px', padding: '2px' }} fontSize={38} color="#036445" onClick={handleBackClick}/>
                </div>
                <div>
                    <h2 style={{ fontWeight: 400, fontSize: 24, color: 'white', margin: '0' }}>{title}</h2>
                    <p style={{ fontWeight: 400, fontSize: 14, color: 'white', margin: '0' }}>{sub_title}</p>
                </div>
            </div>
        </div>
    )
}
