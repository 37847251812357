import React from 'react';
import ReactDOM from 'react-dom/client';
import { BrowserRouter, Routes, Route } from "react-router-dom";
import 'bootstrap/dist/css/bootstrap.min.css';

import App from './App';
import Login from './page/login';
import Register from './page/register';
import Map from './page/map'
import MapEdit from './page/mapEdit'
import Home from './page/home'

import MapPreview from './page/map-preview'

import MapTest from './page/mapTest'
import MapTest2 from './page/mapTest2'
import RiceCalenderMainPage from './page/rice-calendar/main';
import RiceCalenderDetailsPage from './page/rice-calendar/details';
import Example from './page/example';



// import MapDesktop from './page/mapDesktop';

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <BrowserRouter>
    <Routes>
      <Route path="/" element={<App />} />
      <Route path="/user/login" element={<Login />} />
      <Route path="/user/register" element={<Register />} />
      <Route path="/home" element={<Home />} />
      <Route path="/map/:action?" element={<Map />} />
      <Route path="/edit" element={<MapEdit />} />
      <Route path="/map-preview" element={<MapPreview />} />
      <Route path="/map/test" element={<MapTest />} />
      <Route path="/map/test2" element={<MapTest2 />} />
      <Route path="/rice/calendar" element={<RiceCalenderMainPage />} />
      <Route path="/rice/calendar/detail?" element={<RiceCalenderDetailsPage/>} />


      {/* test */}
      {/* <Route path="/map-desktop" element={<MapDesktop />} /> */}
    </Routes>
  </BrowserRouter>
);