import React, { useRef, useEffect, useState } from 'react';
import { MapContainer, TileLayer, Marker, Polygon, useMap } from 'react-leaflet';
import 'leaflet/dist/leaflet.css'; // Import the Leaflet CSS
import L from 'leaflet';
import * as turf from '@turf/turf';
import { Row, Button,Form} from 'react-bootstrap';
import { LuUndo, LuPlus, LuCheck, LuLocateFixed, LuCalculator, LuCamera } from "react-icons/lu";
import { TbMathXy, TbShape, TbLine } from "react-icons/tb"
import { FiChevronLeft } from "react-icons/fi";
import { useLocation, useNavigate } from "react-router-dom";
// import axios from 'axios';
// import * as mapHttpServices from '../services/map-service'
import crosshair from './../assets/images/crosshair_black.png';
import LoadingOverlay from "react-loading-overlay";
LoadingOverlay.propTypes = undefined

const MapComponent = () => {
    const navigate = useNavigate();
    const { state } = useLocation();

    const mapRef = useRef();
    const markerRef = useRef();
    const [markers, setMarkers] = useState([]);
    const [polygon, setPolygon] = useState(null);
    const [initialPosition, setInitialPosition] = useState([13.7367, 100.5231]);
    const [isBusy, setBusy] = useState(true)
    const [shape, setShape] = useState(false)
    const [area, setArea] = useState(state?.area || 0)
    const [name, setName] = useState(state?.name || 0)
    const [note, setNote] = useState(state?.note || 0)
    const newCreate = !(state?.edit) || true

    // const setColor = ({ properties }) => {
    //     return { weight: 1 };
    // };
    // const mapRef = useRef(null);
    // const [currentLocation, setCurrentLocation] = useState(null);

    // const [shape, setShape] = useState(false)
    // const [selectedFeature, setSelectedFeature] = useState(null);

    // const [pinCoordinates, setPinCoordinates] = useState(null);
    // const [markerCoordinates, setMarkerCoordinates] = useState([]);
    // const [polygonCoordinates, setPolygonCoordinates] = useState([]);
    // const [area, setArea] = useState(0)


    // const [name, setName] = useState("")
    // const [note, setNote] = useState("")

    // const [geojsonData, setGeojsonData] = useState(null);

    // const onEachFeature = (feature, layer) => {
    //     layer.on({
    //         click: () => {
    //             setSelectedFeature(feature);
    //         }
    //     });
    // };

    // useEffect(() => {
    //     const fetchData = async () => {
    //         try {
    //             const response = await axios.get('https://maps-server.ricethailand.go.th/geoserver/Rice/ows?service=WFS&version=1.0.0&request=GetFeature&typeName=Rice:rice_sql&viewparams=id:06;farmer_id:1;project_id:238&outputFormat=application/json&format_options=callback:getJson');
    //             console.log('response', response.data)
    //             setGeojsonData(response.data);
    //         } catch (error) {
    //             console.error('Error fetching data from Geoserver:', error);
    //             alert('Error fetching data from Geoserver')
    //         }
    //     };

    //     fetchData();
    // }, []);


    useEffect(() => {
        if ("geolocation" in navigator) {
            navigator.geolocation.getCurrentPosition((position) => {
                const { latitude, longitude } = position.coords;
                console.log(latitude, longitude)
                if (mapRef.current) {
                    setInitialPosition([latitude, longitude]);
                    mapRef.current.flyTo([latitude, longitude], 18);
                    setBusy(false)
                }
            });
        }
        else {
            console.log("Geolocation is not supported by your browser")
            alert("Geolocation is not supported by your browser")
        }
    }, []);

    const handleSubmit = async () => {
        try {
            const coordinates = markers.map(item => ({ ...item._latlng }));
            const nestedArray = coordinates.map(coord => [coord.lat, coord.lng]);

            const obj = {
                map_data: {
                    type: "Feature",
                    id: "06",
                    properties: {
                        name: "test6",
                        density: 0,
                        farmer_id: 1,
                        project_id: Math.floor(Math.random() * (1000 - 1 + 1)) + 1
                    },
                    geometry: {
                        type: "Polygon",
                        coordinates: [nestedArray]
                    }
                }
            };

            console.log('obj', obj);

            // Uncomment the following lines when you're ready to make the API call
            // const res = await mapHttpServices.saveMap(obj);
            // console.log(res);
            // if (res.data.response.status) {
            //     alert('Success');
            // }
        } catch (error) {
            // Handle any errors here
            console.error(error);
        }
    };

    const crosshairsIcon = new L.Icon({
        iconUrl: crosshair,
        iconSize: [32, 32],
        iconAnchor: [16, 16],
    });

    const MoveCrosshairs = () => {
        const map = useMap();

        useEffect(() => {
            const updateMarkerPosition = () => {
                const { lat, lng } = map.getCenter();
                markerRef.current.setLatLng([lat, lng]);
            };

            map.on('move', updateMarkerPosition);
            updateMarkerPosition(); // Initial position update

            return () => {
                map.off('move', updateMarkerPosition);
            };
        }, [map]);

        return null;
    };

    const calculatePolygonArea = (polygonRef) => {
        if (polygonRef.length >= 3) {
            console.log('polygonRef', polygonRef[0]._latlng)
            const coordinates = polygonRef.map(item => ({ ...item._latlng }));
            // console.log('coordinates', coordinates)

            coordinates.push({ lat: coordinates[0].lat, lng: coordinates[0].lng });
            // console.log('coordinates2', ...coordinates)

            const nestedArray = coordinates.map(coord => [coord.lat, coord.lng]);
            // console.log(nestedArray);

            const polygon = turf.polygon([nestedArray]);
            const area = turf.area(polygon);
            // console.log('area', area)

            setArea(area)
        } else {
            setArea(0)
        }
    }

    const updatePolygon = (updatedMarkers) => {
        const positions = updatedMarkers.map(marker => marker.getLatLng());
        setPolygon(<Polygon positions={positions} />);

        calculatePolygonArea(updatedMarkers)
    };

    const handleAdd = () => {
        const { lat, lng } = markerRef.current.getLatLng();
        console.log('Clicked at:', lat, lng);

        const newMarkerIcon = new L.divIcon({
            className: 'custom-marker',
            html: `<div class="circle-icon"}>${markers.length + 1}</div>`,
            iconSize: [32, 32],
            iconAnchor: [16, 16],
        });

        const newMarker = L.marker([lat, lng], { icon: newMarkerIcon }).addTo(mapRef.current);

        setMarkers((prevMarkers) => [...prevMarkers, newMarker]);
        updatePolygon([...markers, newMarker]);
    };

    const handleUndo = () => {
        const lastMarker = markers.pop();
        if (lastMarker) {
            mapRef.current.removeLayer(lastMarker);
            setMarkers([...markers]);
            updatePolygon([...markers]);
        }
    };


    return (
        <div>
            <LoadingOverlay
                active={isBusy}
                spinner
                text={<p style={{ color: 'white', fontSize: '16px' }}>กำลังโหลด</p>}
            >
                <div style={{ height: '100vh' }}>
                    <div className="shadow-lg maps-header">
                        <div style={{ display: 'flex', alignItems: 'center', margin: '20px 0' }}>
                            <div style={{ margin: '0 20px' }}>
                                <FiChevronLeft style={{ backgroundColor: "white", borderRadius: '50px', padding: '2px' }} fontSize={38} color="#036445" onClick={() => navigate('/home')} />
                            </div>
                            <div>
                                <h2 style={{ fontWeight: 400, fontSize: 24, color: 'white', margin: '0' }}>{newCreate ? 'เพิ่มแปลง' : 'แก้ไขแปลง'}</h2>
                                <p style={{ fontWeight: 400, fontSize: 14, color: 'white', margin: '0' }}>กรุณากรอกข้อมูลให้ครบถ้วน</p>
                            </div>
                        </div>
                    </div>


                    <div style={{ margin: '-40px 20px 0 20px', border: '1px solid gray' }}>
                        <MapContainer
                            center={initialPosition}
                            zoom={15}
                            style={{ width: '100%', height: '330px' }}
                            ref={mapRef}
                            zoomControl={false}
                        >
                            <TileLayer
                                url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                                attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
                            />

                            <MoveCrosshairs />
                            <Marker position={initialPosition} icon={crosshairsIcon} ref={markerRef} zIndexOffset={1000} />

                            {polygon}
                        </MapContainer>
                    </div>


                    <div className="front-menu1" style={{ marginTop: '-45px' }}>
                        <LuUndo
                            fontSize={40}
                            style={{
                                color: '#ffffff',
                                backgroundColor: markers.length > 0 ? '#016545' : '#a9a9a9',
                                borderRadius: '50%',
                                padding: '10',
                                marginTop: '10px',
                                marginRight: '10px',
                                boxShadow: '2px 2px 4px rgba(0, 0, 0, 0.2)'
                            }}
                            onClick={() => handleUndo()}
                        />
                        <LuPlus
                            fontSize={60}
                            style={{
                                color: '#ffffff',
                                backgroundColor: '#016545',
                                borderRadius: '50%',
                                padding: '5',
                                boxShadow: '2px 2px 4px rgba(0, 0, 0, 0.2)'
                            }}
                            onClick={() => handleAdd()}
                        />
                        <LuCheck
                            fontSize={40}
                            style={{
                                color: '#ffffff',
                                backgroundColor: markers.length > 2 ? '#016545' : '#a9a9a9',
                                borderRadius: '50%',
                                padding: '10px',
                                marginTop: '10px',
                                marginLeft: '10px',
                                boxShadow: '2px 2px 4px rgba(0, 0, 0, 0.2)'
                            }}
                            onClick={() => console.log(markers)}
                        />
                    </div>

                    <div className="front-menu2" style={{ marginTop: '-155px' }}>
                        <div
                            style={{
                                backgroundColor: '#ffffff',
                                borderRadius: '50px',
                                // marginTop: '10px',
                                boxShadow: '2px 2px 4px rgba(0, 0, 0, 0.2)'
                            }}
                        >
                            <TbMathXy
                                fontSize={40}
                                style={{ padding: '10px' }}
                            // onClick={() => handlePinLocation()}
                            // color={pinCoordinates ? '#016545' : '#000'}
                            />
                        </div>
                        <div
                            style={{
                                backgroundColor: 'rgba(255, 255, 255, 0.5)',
                                borderRadius: '50px',
                                marginTop: '10px',
                                boxShadow: '2px 2px 4px rgba(0, 0, 0, 0.2)'
                            }}
                        >
                            {
                                shape ?
                                    <TbLine
                                        fontSize={40}
                                        style={{ padding: '10px' }}
                                        onClick={() => {
                                            // setShape(true);
                                            alert('This feature is currently unavailable.')
                                        }}
                                    />
                                    :
                                    <TbShape
                                        fontSize={40}
                                        style={{ padding: '10px' }}
                                        onClick={() => {
                                            // setShape(false); 
                                            alert('This feature is currently unavailable.')
                                        }}
                                    />
                            }
                        </div>
                    </div>

                    <div className="front-menu3" style={{ marginTop: '-155px' }}>
                        <div style={{
                            backgroundColor: '#ffffff',
                            borderRadius: '50px',
                            boxShadow: '2px 2px 4px rgba(0, 0, 0, 0.2)'
                        }}>
                            <LuLocateFixed
                                fontSize={40}
                                style={{ padding: '10px' }}
                                onClick={() => mapRef.current.flyTo(initialPosition, 18)}
                            />
                        </div>
                        <div style={{
                            backgroundColor: 'rgba(255, 255, 255, 0.5)',
                            borderRadius: '50px',
                            marginTop: '120px',
                            boxShadow: '2px 2px 4px rgba(0, 0, 0, 0.2)'
                        }}>
                            <LuCalculator
                                fontSize={40}
                                style={{ padding: '10px' }}
                                onClick={() => alert('This feature is currently unavailable.')}
                            />
                        </div>
                        <div style={{
                            backgroundColor: 'rgba(255, 255, 255, 0.5)',
                            borderRadius: '50px',
                            marginTop: '10px',
                            boxShadow: '2px 2px 4px rgba(0, 0, 0, 0.2)'
                        }}>
                            <LuCamera
                                fontSize={40}
                                style={{ padding: '10px' }}
                                onClick={() => alert('This feature is currently unavailable.')}
                            />
                        </div>
                    </div>

                    <div style={{ padding: '20px' }}>
                        <div className="shadow-sm mb-3" style={{ backgroundColor: 'white', borderRadius: '10px' }}>
                            <div style={{ color: 'black', padding: '10px' }}>
                                <span>ขนาดพื้นที่: </span>
                                <span style={{ color: '#016545' }}>{Math.round(parseFloat(area / 1600))} ไร่ </span>
                                <span style={{ color: '#016545' }}>{Math.round(parseFloat(area % 1600) / 400)} งาน </span>
                                <span style={{ color: '#016545' }}>{Math.round(parseFloat(area % 400) / 4)} ตารางวา</span>
                            </div>
                        </div>
                        <Form>
                            <Form.Group className="mb-3" controlId="formBasicEmail">
                                <Form.Label style={{ color: 'black' }}>ชื่อแปลง</Form.Label>
                                <Form.Control className="shadow-sm" type="name" placeholder="กรอกชื่อแปลง" onChange={(e) => setName(e.target.value)} />
                            </Form.Group>
                        </Form>
                        <Form>
                            <Form.Group className="mb-3" controlId="formBasicEmail">
                                <Form.Label style={{ color: 'black' }}>หมายเหตุ</Form.Label>
                                <Form.Control className="shadow-sm" type="note" placeholder="กรอกหมายเหตุ" onChange={(e) => setNote(e.target.value)} />
                            </Form.Group>

                            <Row style={{ padding: '40px 12px 0px 12px' }}>
                                <Button style={{ backgroundColor: '#016545', borderColor: '#016545' }} onClick={() => handleSubmit()}>บันทึก</Button>
                            </Row>
                        </Form>
                    </div>
                </div >
            </LoadingOverlay>
        </div>
    );
};

export default MapComponent;


